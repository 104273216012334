@import "variables";

.Loader {
    width: 3rem;
    height: 3rem;
    border-radius: 100%;
    margin: 2rem auto;
    border: 5px solid transparent;
    border-bottom-color: $cao-medium-blue;
    border-top-color: $cao-medium-blue;
    animation: rotate .8s linear 0s infinite;

    &-backcover::before {
        opacity: .8;
        z-index: 999998;
        display: block;
        position: fixed;
        background-color: $white;
        content: '';
        width: 100%;
        height: 100%;
    }
}

.btn-orange {
    color: $white;
    background-color: $cao-orange;

    &:hover {
        color: $white;
        background-color: $cao-orange-hover;
    }
}